.dev-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark-dev-container {
    background-color: rgb(24 24 27);
}

.light-dev-container {
    background-color: rgb(237 233 254);
}

.dev-box {
    width: 180px;
    min-height: 260px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.288);
    border-radius: 20px;
    flex-direction: column;
    columns: #fff;
    position: relative;
    overflow: hidden;
}
.dev-box img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.user_name {
    margin-bottom: 5px;
    font-size: 1.5rem;
    font-weight: 600;
}

.skills {
    color: rgba(255,255,255,0.555);
}
.arr_container, .cancel {
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 0;
    right: 0;
    border-radius: 23px 0 23px 0;
    font-size: 1.6rem;
    cursor: pointer;
    transition: all .4s;
}

.dark-dev-arrow-container{
    background-color: rgb(23 37 84);
}

.light-dev-arrow-container{
    background-color: rgb(253 230 138);
}

.arr_container i{
    transform: rotate(45deg);
}
.active_arr {
    transform: translate(80%, 80%);
}
.left_container {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 23px;
    padding: 20px;
    transition: all .4s;
}

.low-opacity {
    opacity: 0.15;
    transition: opacity 0.5s ease;
}

.high-opacity {
    opacity: 1;
    transition: opacity 0.5s ease;
}

.off{
    transform: translate(-80%, -80%) rotate(90deg);
}
.active {
    transform: translate(0) rotate(0);
}
.left_container p{
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: #000;
}
.left_container .skills div{
    display: inline-block;
    color: #000;
    border: 1px solid rgb(155,155,155);
    padding: 5px 10px;
    font-size: .9rem;
    margin: 4px 4px 4px 0;
}
.left_container .icons{
    font-size: 1.6rem;
    margin-top: 10px;
}
.left_container .icons i{
    cursor: pointer;
    margin-right: 10px;
    transition: all .4s;
}
.left_container .icons i:hover {
    color: rgb(151, 150, 150);
}

@media (min-width: 250px) {
    .dev-box {
        width: 230px;
    }
}

@media (min-width: 400px) {
    .dev-box {
        width: 320px;
    }
}

@media (max-width: 400px) {
    .dev-box {
        height: 350px;
    }
}

@media (min-width: 720px) {
    .dev-box {
        width: 350px;
    }
}

@media (min-width: 1080px) {
    .dev-box {
        width: 375px;
    }
}

@media (min-width: 250px) {
    .dev-box img {
        width: 125px;
        height: 125px;
    }
}

@media (min-width: 400px) {
    .dev-box img {
        width: 140px;
        height: 140px;
    }
}

@media (min-width: 720px) {
    .dev-box img {
        width: 155px;
        height: 155px;
    }
}

@media (min-width: 1080px) {
    .dev-box img {
        width: 165px;
        height: 165px;
    }
}