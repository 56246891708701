@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.auth {
    min-height: calc(100vh - 3.75rem);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}

.dark-mode-auth {
    background: linear-gradient(to right, rgb(15 23 42), rgb(24 24 27), rgb(23 23 23));
}

.light-mode-auth {
    background-color: white;
}

.auth-container {
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 25rem;
}

@media only screen and (max-width: 525px) {
    .auth-container {
        min-height: 35rem;
    }
}

.dark-auth-container {
    background-color: rgb(17 24 39) !important;
}

.light-auth-container {
    background-color: #fff !important;
}

.auth-container p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.auth-container span {
    font-size: 12px;
}

.auth-container a {
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
}

.auth-container .auth-button {
    background-color: orange;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.sign-up-clickable.auth-button:hover {
    background-color: #00a1ff !important;
}

.sign-up-clickable {
    background-color: orange !important;
    cursor: pointer !important;
}

.sign-up-disabled {
    background-color: gray !important;
    cursor: not-allowed !important;
}

.auth-container .auth-button.toggle-button {
    background-color: transparent;
    border-color: #fff;
}

.auth-container form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.auth-container input {
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}

.light-input {
    background-color: #eee;
}

.dark-input {
    background-color: rgb(71 85 105);
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: color 0.6s ease-in-out, border 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.sign-in {
    left: 0;
    width: 50%;
    z-index: 2;
}

.auth-container.active .sign-in {
    transform: translateX(100%);
}

.sign-up {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.auth-container.active .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move {

    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

.social-icons {
    margin: 20px 0;
}

.social-icons a {
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
}

.toggle-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 40;
}

.auth-container.active .toggle-container {
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.toggle {
    background-color: linear-gradient(to left, #00a1ff, #00ff8f);
    height: 100%;
    background: linear-gradient(to right, #C33764, #1D2671);
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.auth-container.active .toggle {
    transform: translateX(50%);
}

.toggle-panel {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggle-left {
    transform: translateX(-200%);
}

.auth-container.active .toggle-left {
    transform: translateX(0);
}

.toggle-right {
    right: 0;
    transform: translateX(0);
}

.auth-container.active .toggle-right {
    transform: translateX(200%);
}
