.user-friend {
    min-height: calc(100vh - 3.75rem);
}

@media screen and (min-width: 300px) {
    .search-bar {
        width: 275px;
    }
}

@media screen and (min-width: 350px) {
    .search-bar {
        width: 300px;
    }
}

@media screen and (min-width: 520px) {
    .search-bar {
        width: 350px;
    }
}

@media screen and (min-width: 620px) {
    .search-bar {
        width: 450px;
    }
}

@media screen and (min-width: 700px) {
    .search-bar {
        width: 400px;
    }
}

@media screen and (min-width: 900px) {
    .search-bar {
        width: 500px;
    }
}

@media screen and (min-width: 1080px) {
    .search-bar {
        width: 600px;
    }
}