@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

.tagline {
    color: rgb(38, 15, 15);
    font-family: "Fredoka", sans-serif;
    background-color: rgb(224 231 255);
    padding: 10px !important;
    cursor: pointer;
}

@media only screen and (min-width: 1024px) {

    .landing-page-top {
        min-height: calc(100vh - 3.75rem);
    }

    .typing-text-lg-device {
        color: black !important;
    }

    .welcome-heading-lg-device {
        color: rgb(30 58 138) !important;
    }
}