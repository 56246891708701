.password-group { 
    width: 100%; 
    text-align: center; 
} 
  
.password-group .power-container {  
    width: 100%; 
    height: 8px; 
} 

.dark-password-checker {
    background-color: #2E424D;
}

.light-password-checker {
    background-color: rgb(218, 218, 218);
}
  
.password-group .power-container #power-point { 
    background-color: #D73F40; 
    width: 1%; 
    height: 100%;
    transition: 0.5s; 
}