/* body{
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	font-family: 'Jost', sans-serif;
	background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
} */
.main {
	/* width: 350px; */
	height: 650px;
	overflow: hidden;
}

#chk {
	display: none;
}

.signup {
	position: relative;
	width: 100%;
	height: 100%;
}

.mobile-auth-label {
	font-size: 2rem;
	justify-content: center;
	display: flex;
	margin: 60px;
	font-weight: bold;
	transition: transform .5s ease-in-out;
}

.mobile-auth-input {
	width: 90%;
	height: 30px;
	justify-content: center;
	display: flex;
	margin: 20px auto;
	border: none;
	outline: none;
}

.mobile-auth-btn {
	width: 40%;
	height: 40px;
	margin: 10px auto;
	justify-content: center;
	display: block;
	font-size: 1em;
	font-weight: bold;
	margin-top: 20px;
	outline: none;
	border: none;
	border-radius: 5px;
	transition: transform .2s ease-in;
}

.login {
	height: 610px;
	border-radius: 60% / 10%;
	transform: translateY(-180px);
	transition: transform .8s ease-in-out;
}

.login label {
	transform: scale(.6);
}

#chk:checked~.login {
	transform: translateY(-650px);
}

#chk:checked~.login label {
	transform: scale(1);
}

#chk:checked~.signup label {
	transform: scale(.6);
}

.sign-in-dark {
	background: linear-gradient(to right, #1D2671, #C33764);
}

.sign-up-dark {
	background: linear-gradient(to right, #C33764, #1D2671);
}

.sign-in-light {
	background: linear-gradient(to right, #1D2671, #C33764);
}

.sign-up-light {
	background: linear-gradient(to right, #C33764, #1D2671);
}

.sign-in-input-dark {
    background: linear-gradient(to right, #111827, #18181b, #18181b);
}

.sign-up-input-dark {
    background: linear-gradient(to right, #1f2937, #27272a, #27272a);
}

.sign-in-input-light {
    background: linear-gradient(to right, #f3e8ff, #e0e7ff, #e0e7ff);
}

.sign-up-input-light {
    background: linear-gradient(to right, #cffafe, #dbeafe, #dbeafe);
}

.light-placeholder::placeholder {
	color: #020202 !important;
	/* Light mode placeholder color */
}

.dark-placeholder::placeholder {
	color: #CCCCCC !important;
	/* Dark mode placeholder color */
}