.dark-mode-shadow {
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.2) !important;
}

.bg-sky-100 {
    background-color: rgb(224 242 254);
}

.bg-sky-50 {
    background-color: rgb(240 249 255);
}

.bg-yellow-50 {
    background-color: rgb(254 252 232);
}

.bg-cyan-50 {
    background-color: rgb(236 254 255);
}

.border-blue-600 {
    border-color: rgb(37 99 235);
}

.bg-zinc-900 {
    background-color: rgb(24 24 27);
    ;
}

.bg-zinc-700 {
    background-color: rgb(63 63 70);
}

.bg-zinc-800 {
    background-color: rgb(39 39 42);
}

.bg-red-50 {
    background-color: rgb(254 242 242);
}

.bg-cyan-50 {
    background-color: rgb(236 254 255);
}

.bg-slate-900 {
    background-color: rgb(15 23 42);
}

.bg-indigo-50 {
    background-color: rgb(238 242 255);
}

.h-50 {
    height: 12.5rem;
}

.game {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.navbar-hidden {
    min-height: 100vh;
}

.captured-pieces-container {
    position: relative;
}

.captured-pieces-container>div {
    position: absolute;
}

.bg-blur {
    opacity: 0.95;
}

.bg-unblur {
    opacity: 1;
}

.min-h-32 {
    min-height: 8rem;
}

.min-w-32 {
    min-width: 8rem;
}

.h-32 {
    height: 8rem;
}

.bottom-8 {
    bottom: 5rem;
}

@media (min-width: 768px) {
    .md\:h-48 {
        height: 12rem
            /* 192px */
        ;
    }
}

@media (min-width: 768px) {
    .md\:w-96 {
        width: 24rem
            /* 384px */
        ;
    }
}

.bg-zinc-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.bg-zinc-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.bg-gray-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.max-w-20 {
    max-width: 5rem
        /* 80px */
    ;
}

.max-w-32 {
    max-width: 8rem
        /* 128px */
    ;
}

.w-36 {
    width: 9rem
        /* 144px */
    ;
}

.w-28 {
    width: 7.5rem
        /* 112px */
    ;
}

.w-34 {
    width: 8.25rem
}

.max-w-96 {
    max-width: 24rem
        /* 384px */
    ;
}

@media (min-width: 640px) {
    .sm\:w-60 {
        width: 15rem
            /* 240px */
        ;
    }
}

@media (min-width: 250px) {
    .xs\:w-48 {
        width: 12rem
            /* 240px */
        ;
    }
}

@media (min-width: 768px) {
    .md\:w-96 {
        width: 24rem;
    }
}

@media (min-width: 640px) {
    .sm\:w-80 {
        width: 20rem;
    }
}

@media (min-width: 320px) {
    .xs\:w-72 {
        width: 18rem;
    }
}

@media (min-width: 250px) {
    .xxs\:w-60 {
        width: 15rem;
    }
}

@media (min-width: 640px) {
    .sm\:w-56 {
        width: 14rem
            /* 224px */
        ;
    }
}

@media (min-width: 431px) {
    .sm\:w-96 {
        width: 24rem
            /* 224px */
        ;
    }
}

@media (max-width: 430px) {
    .xs\:w-64 {
        width: 16rem
            /* 224px */
        ;
    }
}

@media (max-width: 255px) {
    .xxs\:w-48 {
        width: 12rem
            /* 224px */
        ;
    }
}

.text-start {
    text-align: start;
}

.h-full {
    height: 100%;
}

.justify-start {
    justify-content: flex-start;
}

.items-start {
    align-items: flex-start;
}

.game-layout {
    min-height: calc(100vh - 3.75rem);
}

.support {
    min-height: calc(100vh - 3.75rem);
}

@media (min-width: 640px) {
    .sm\:text-5xl {
        font-size: 3rem
            /* 48px */
        ;
        line-height: 1;
    }
}

.top-register {
    top: 1.125rem;
}

.min-height-without-navbar {
    min-height: calc(100vh - 3.75rem);
}

@media (min-width: 640px) {
    .sm\:text-4xl {
        font-size: 2.25rem
            /* 36px */
        ;
        line-height: 2.5rem
            /* 40px */
        ;
    }
}

@media (min-width: 768px) {
    .md\:gap-4 {
        gap: 1rem
            /* 16px */
        ;
    }

    .md\:rounded-tr-none {
        border-top-right-radius: 0px;
    }
}

@media (min-width: 1024px) {
    .lg\:gap-5 {
        gap: 1.25rem
            /* 20px */
        ;
    }
}

@media (min-width: 1024px) {
    .lg\:justify-between {
        justify-content: space-between;
    }
}

@media (min-width: 500px) {
    .sm\:flex-row {
        flex-direction: row;
    }

    .sm\:h-60 {
        height: 15rem;
    }

    .sm\:w-60 {
        width: 15rem
            /* 192px */
        ;
    }

    .sm\:rounded-l-lg {
        border-top-left-radius: 0.5rem
            /* 8px */
        ;
        border-bottom-left-radius: 0.5rem
            /* 8px */
        ;
    }

    .sm\:rounded-tr-none {
        border-top-right-radius: 0px;
    }
}

.hover\:opacity-25:hover {
    opacity: 0.25;
}

.hover\:opacity-75:hover {
    opacity: 0.75;
}

.text-xl {
    font-size: 1.25rem
        /* 20px */
    ;
    line-height: 1.75rem
        /* 28px */
    ;
}

@media (min-width: 640px) {
    .sm\:text-2xl {
        font-size: 1.5rem
            /* 24px */
        ;
        line-height: 2rem
            /* 32px */
        ;
    }
}

@media (min-width: 768px) {
    .md\:text-3xl {
        font-size: 1.875rem
            /* 30px */
        ;
        line-height: 2.25rem
            /* 36px */
        ;
    }
}

.bg-inherit {
    background-color: inherit;
}

@media (min-width: 1024px) {
    .lg\:justify-end {
        justify-content: flex-end;
    }
}

@media (min-width: 1024px) {
    .lg\:pr-10 {
        padding-right: 10rem
            /* 40px */
        ;
    }
}

.bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

.bg-violet-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.bg-violet-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(46 16 101 / var(--tw-bg-opacity));
}

.bg-stone-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(12 10 9 / var(--tw-bg-opacity));
}

.bg-stone-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

@media (max-width: 500px) {
    .bottom-toast {
        max-width: 90vw;
    }
}

.text-gray-100 {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity));
}

.duration-60000 {
    transition-duration: 60000ms;
}

.animate-spin-60 {
    animation: spin 60s linear infinite;
}