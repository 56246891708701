@import url("https://fonts.googleapis.com/css2?family=Muli&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap');

.carousel-container {
  display: flex;
  width: 98.5vw;
  overflow: hidden;
  font-family: "Muli", sans-serif;
}

.panel {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80vh;
  border-radius: 50px;
  cursor: pointer;
  color: #fff;
  flex: 0.5;
  margin: 10px;
  position: relative;
  transition: flex 0.7s ease-in;
}

.panel h3 {
  font-size: 24px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin: 0;
  opacity: 0;
  background-color: #111;
  padding: 10px 10px;
  border-radius: 7px;
  color: #fff;
  font-weight: bold;
}

.panel.active {
  flex: 5;
}

.panel.active h3 {
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.3 ease-in-out 0.4s;
}


.features-heading {
  color: hsl(272, 98%, 50%);
  font-weight: bold!important;
  font-family: monospace;
  letter-spacing: 7px!important;
  cursor: default;
  text-transform: uppercase;
}

.features-heading {
  background: linear-gradient(to right, hsl(273, 100%, 50%) 0, hsl(294, 100%, 83%) 10%, hsl(273, 100%, 50%) 20%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite alternate;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 600px;
  }
  100% {
    background-position: 600px;
  }
}

@keyframes float{
  0%,100%{
      clip-path: polygon(
          0% 35%,
          14% 48%,
          35% 60%,
          54% 66%,
          70% 61%,
          84% 59%,
          100% 52%,
          100% 100%,
          0% 100%
      );
  }
  50%{
      clip-path: polygon(
          0% 60%,
          15% 65%,
          34% 66%,
          51% 62%,
          67% 50%,
          84% 45%,
          100% 33%,
          100% 100%,
          0% 100%
      )
  }
}

@media (max-width: 768px) {
  .panel{
    height: 30vh;
    cursor: default;
  }
}
