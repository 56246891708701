.features-game {
    min-height: calc(100vh - 3.75rem);
    margin: 0 !important;
}

.game {
    min-height: calc(100vh - 3.75rem);
}

.mb-0 {
    margin-bottom: 0px !important;
}