.nav-elements {
    text-transform: uppercase;
}

.activenav.light-mode-nav-elements {
    color: rgb(104, 0, 231);
    font-weight: bolder;
}

.activenav.dark-mode-nav-elements {
    color: rgb(196 181 253);
}

.activenav.light-mode-nav-elements.underline-hover-effect::before {
    background-color: rgb(104, 0, 231);
}

.activenav.dark-mode-nav-elements.underline-hover-effect::before {
    background-color: rgb(196 181 253);
}

.underline-hover-effect {
    text-decoration: none;
}

.underline-hover-effect {
    display: inline-block;
    position: relative;
}

.underline-hover-effect::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    transition: width 0.25s ease-out;
}

.light-mode-nav-elements.underline-hover-effect::before {
    background-color: black;
}

.dark-mode-nav-elements.underline-hover-effect::before {
    background-color: white;
}

.underline-hover-effect:hover::before {
    width: 100%;
}

.icon {
    height: 40px;
    width: 40px;
    padding: 5px;
    border-radius: 4px;
}

.bar {
    height: 5px;
    width: 100%;
    border-radius: 10px;
    display: block;
    transition: all .5s ease;
}

.dark-mode-ham {
    background: #fff;
}

.light-mode-ham {
    background: #555555;
}

#bar2 {
    transform: translateY(6px);
}

#bar3 {
    transform: translateY(12px);
}

.change #bar1 {
    margin-top: 5px;
    width: 33px;
    transform: rotateZ(45deg) translate(2px, 5px);
}

.change #bar2 {
    opacity: 0;
}

.change #bar3 {
    width: 33px;
    transform: rotateZ(-45deg) translate(2px, -5px);
}

.menu {
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    font-family: monospace;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    animation: openMenu .5s ease-in-out forwards;
    transform-origin: top center;
    display: none;
    position: relative;
    right: 0.5rem;
    text-transform: uppercase;
}

.dark-mode-mobile-navbar {
    background: linear-gradient(to right, rgb(30 41 59), rgb(38 38 38), rgb(39 39 42));
    box-shadow: 0 4px 12px 0 rgba(255, 255, 255, 0.2) !important;
}

.light-mode-mobile-navbar {
    background-color: white;
}

.menu li {
    list-style: none;
    display: block;
    margin: 10px 20px;
    padding: 15px 25px;
    text-align: center;
}

.dark-mode-nav-border {
    border-bottom: 2px solid rgba(82, 82, 91, 0.2);
}

.light-mode-nav-border {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.menu li Link {
    text-decoration: none;
    color: rgb(55, 55, 55);
}

.menu li:nth-last-child(1) {
    border-bottom: none;
    padding-bottom: 20px;
}

.menu li:hover {
    transform: scale(1.1);
    transition: 0.2s ease;
}

.open {
    display: block;
    position: fixed;
}

.profile-image {
    margin-left: 15px;
}

.border-gray-700-2 {
    border-bottom: 2px solid rgba(82, 82, 91, 0.2);
}

@keyframes openMenu {
    0% {
        transform: scaleY(0)
    }

    80% {
        transform: scaleY(1.1)
    }

    100% {
        transform: scaleY(1)
    }
}

@media only screen and (min-width: 800px) {
    .theme-changer {
        gap: 15px !important;
    }

    .nav-button-group {
        gap: 10px !important;
    }

    .profile-image {
        margin-left: 0;
    }
}